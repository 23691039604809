#contact {
    height: auto;
    width: 100vw;
    max-width: 60rem;
    margin: 2rem auto;
    text-align: center;
}

.contactPageTitle {
    font-size: 3rem;
}

.contactDescription {
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.submitBtn {
    background: white;
    border: none;
    border-radius: 2rem;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    font-size: 1rem;
}

.submitBtn:hover {
    cursor: pointer;
}

.links {
    display: flex;
    flex-wrap: wrap;
}

.link {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.5rem;
}