#intro {
    height: calc(100vh - 5rem);
    width: 100vw;
    margin: 0 auto;
    padding: 2rem 0;
    overflow: hidden;
}

.bg {
    position: absolute;
    top: 6rem;
    right: 5vw;
    z-index: -1;
    object-fit: cover;
    height: calc(100vh - 5rem);
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName {
    color: yellow;
}

.introPara {
    width: 50vw;
    min-width: 20rem;
    font-size: medium;
    font-weight: 300;
}

.btn {
    background: white;
    margin: 1rem 0;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 2rem;
}

.hiremeImg {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem 0.5rem 1rem 0;
}

@media screen and (max-width:840px) {
    .bg {
        right: 15vw
    }
    .introContent {
        font-size: 5vw;
    }
    .hello {
        font-size: 2.5vw
    }
}

@media screen and (max-width:480px) {
    .bg {
        right: 0
    }
    .introContent {
        font-size: 10vw;
    }
    .hello {
        font-size: 4.5vw
    }
}