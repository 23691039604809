.navbar {
    background: rgb(20, 20, 20);
    height: 5rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
}

.listItem {
    margin: 1rem;
    cursor: pointer;
}

.listItem:hover {
    color: yellow;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid yellow;
}

.desktopMenuBtn {
    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
}

.desktopMenuBtn:hover {
    cursor: pointer;
}

.desktopMenuImg {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem 0.5rem 1rem 0;
}

.active {
    color: yellow;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid yellow;
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 2.5rem;
}

.navMenu {
    position: absolute;
    top: 5rem;
    right: 0rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: 100vh;
    width: 100vw;
    min-width: 15rem;
    background: linear-gradient(to right, rgba(40, 40, 40, 0.4) 10%,rgba(40, 40, 40, 1) 80%);
    align-items: flex-end;
}

.listItemMob {
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
    text-align: right;
    width: 10rem;
    border-radius: 0.25rem;
}

@media screen and (max-width:720px) {
    .mobMenu{
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }
}