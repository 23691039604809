#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.skillTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.skillDescription {
    font-weight: 300;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;
}

.skillDescriptionBoldText {
    font-weight: 500;
    font-size: 1rem;
    max-width: 50rem;
}

.skillBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.skillBar {
    background: rgb(50, 50, 50);
    display: flex;
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 4px 4px 2px 1px rgba(10, 10, 14, 0.5);
}

.skillBarImg {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;
}

.skillBarText>p {
    font-size: 0.75rem;
    font-weight: 200;
}

.toolBoxes {
    background: rgb(40, 40, 40);
    margin: 1.5rem;
    width: 100vw;
    height:auto;
    max-width: 95%;
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    flex-wrap: wrap;
    border-radius: 1rem;
    box-shadow: 4px 4px 2px 1px rgba(10, 10, 14, 0.5);
}

.toolBox {
    margin: 1rem;
    background: rgba(20, 20, 20, 0.5);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 2px 1px rgba(10, 10, 14, 0.5);
}

.toolBoxImg {
    object-fit: cover;
    margin: 1rem;
    height: 2rem;
    width: 2rem;
}